





















import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';
import { CurrencyCodeType } from '@glittr/frontend-core/src/plugins/localization/currencyCodes';
import Vue from 'vue';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    paymentMethod: 1,
    isLoading: false,
  }),
  computed: {
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
    subscriptionDisplay(): string | undefined {
      const [subscription] = this.innerValue.orderBasket.items ?? [];
      if (subscription) {
        const currency = subscription.currency as CurrencyCodeType;
        const price = this.$format.currency(subscription.unitPrice!, currency);
        const { caption } = subscription;
        return `${caption}: ${price}`;
      }
      return undefined;
    },
  },
  mounted() {
  },
  methods: {
  },
});
